body {
  /*margin: 0;*/
  font-family: 'Facundo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jigpaw {
  width: 100%;
  height: 100%;
  display: block;
}

.jigpaw-image-svg {
    width: 200px;
    height: 200px;
}

.grid {
  max-width: 1000px;
}
.item {
  z-index: 1;
  cursor: pointer;
}
.item.muuri-item-positioning {
  z-index: 2;
}
.item.muuri-item-dragging,
.item.muuri-item-releasing {
  z-index: 3;
}
.item.muuri-item-dragging {
  cursor: move;
}
.item.muuri-item-hidden {
  z-index: 0;
}

.item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid;
  border-radius: 0;
}

.item-content.odd {
  border-right: none;
}

.muuri-item-dragging .item-content.odd {
  border-right: 2px solid;
}


/* main.css */
/* Base */

/*html body {*/
/*  -moz-user-select: none;*/
/*  -webkit-user-select: none;*/
/*  -ms-user-select: none;*/
/*  user-select: none;*/
/*  position: relative;*/
/*  font-family: "Fira Sans", Helvetica, Arial, sans-serif;*/
/*  font-size: 18px;*/
/*  line-height: 1.5;*/
/*  margin: 0 20px;*/
/*  color: #6e6e6e;*/
/*}*/

/* Sections */

section {
  margin: 60px auto;
  padding-top: 0;
  max-width: 1000px;
  border-top: 2px solid #e5e5e5;
  text-align: left;
}
section.demo {
  margin-top: 30px;
}
section h4 {
  margin: 30px 0 15px 0;
  font-weight: 500;
  font-size: 20px;
  color: #3e3e3e;
}
@media (max-width: 600px) {
  section.demo {
    border-top: 0;
  }
}

/* Section titles */

.section-title {
  color: #3e3e3e;
  font-size: 26px;
  font-weight: 700;
  margin: 40px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: center;
}
.section-title > span {
  position: relative;
  display: inline-block;
  vertical-align: top;
}
.section-title > span:after {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  bottom: 0;
  right: 10px;
  height: 2px;
  background: #3e3e3e;
}

.uppy-DragDrop--isDragDropSupported {
  border: none!important;
  border-radius: 0!important;
}